import { sortByProperty } from '@/helpers/sort';
import Triennium from '@/helpers/triennium';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Triennium',
      flex: 1,
      autoHeight: true,
      sort: 'asc',
      sortIndex: 0,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.triennium.from} - ${p.triennium.to}</div>`)
          .join('');
      },
      comparator: (valueA, valueB) => {
        const trienniumYear = Triennium.calculateNumberFromYear(new Date().getFullYear());

        const valueATriennium = valueA.positions[0].triennium.number;
        const valueBTriennium = valueB.positions[0].triennium.number;

        if (valueATriennium === trienniumYear && valueBTriennium === trienniumYear) return 0; // Both are current year
        if (valueATriennium === trienniumYear) return -1; // Only a is current year
        if (valueBTriennium === trienniumYear) return 1; // Only b is current year

        // For years that are not the current year, sort from higher to lower
        if (valueATriennium === valueBTriennium) return 0; // Equal condition, important for secondary sorting
        return valueBTriennium - valueATriennium;
      },
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sortIndex: 1,
      sort: 'asc',
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}
